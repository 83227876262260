import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

const AboutPage = ({ data }) => (
  <Layout>
    <Header />
    <section className="page">
      <div className="container">
        <h1>
          <FormattedMessage id="about_title" />
        </h1>
        {/* <Img
          fluid={data.about.childImageSharp.fluid}
          style={{ marginBottom: "60px" }}
        /> */}
        <p>
          <FormattedMessage id="about" />
        </p>
      </div>
    </section>
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    about: file(relativePath: { eq: "photo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 437) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
